<template>
  <b-row class="justify-content-center">
    <h1>General</h1>
    <b-col md="12">
      <VeeObserver ref="form" slim>
        <VeeProvider name="name" rules="required" v-slot="{ errors }">
          <base-input
            class="input-group-alternative"
            placeholder="Name"
            v-model="payload.name"
            :error="errors[0]"
          />
        </VeeProvider>
        <b-form-group label="Description">
          <ckeditor
            v-model="payload.description"
            name="about"
            :config="{ removeButtons: removeButtons }"
          ></ckeditor>
        </b-form-group>
        <b-form-group label="Social Medias">
          <div v-for="(item, idx) in payload.socialMedias" :key="idx">
            <VeeProvider name="social_name" rules="url" v-slot="{ errors }">
              <base-input
                class="input-group-alternative"
                placeholder="Url"
                :addon-left-icon="getIcon(item.slug)"
                v-model="payload.socialMedias[idx].url"
                :error="errors[0]"
              />
            </VeeProvider>
          </div>
        </b-form-group>
        <VeeProvider name="tags" rules="required" v-slot="{ errors }">
          <b-form-group label="Meta Tags">
            <b-form-tags
              input-id="tags-separators"
              v-model="payload.tags"
              separator=" ,;"
              placeholder="Enter new tags separated by space, comma or semicolon"
              no-add-on-enter
            ></b-form-tags>
            <small class="text-danger" v-if="errors.length">
              {{ errors[0] }}
            </small>
          </b-form-group>
        </VeeProvider>
        <div class="text-center">
          <base-button
            variant="primary"
            type="submit"
            class="my-4"
            @click="save()"
            :loading="loading"
          >
            <i class="fa fa-save"></i> Save
          </base-button>
        </div>
      </VeeObserver>
    </b-col>
  </b-row>
</template>

<script>
import GeneralService from "features/settings/services/GeneralService";

import { removeButtons } from "helpers";

export default {
  name: "FormUsers",
  data: () => ({
    loading: false,
    payload: {
      name: "",
      email: "",
      description: "",
      tags: [],
      socialMedias: [],
    },
    removeButtons,
    selectRoles: [],
  }),
  computed: {
    isEdit() {
      return this.$route.name === "users-edit";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getIcon(slug) {
      switch (slug) {
        case "facebook":
          return "fa fa-facebook";
        case "youtube":
          return "fa fa-youtube";
        case "instagram":
          return "fa fa-instagram";
        case "twitter":
          return "fa fa-twitter";
        case "linkedin":
          return "fa fa-linkedin";
        default:
          return "fa fa-globe";
      }
    },
    async getData() {
      try {
        this.loading = true;
        const { data } = await GeneralService.show();
        this.payload.name = data.site.name;
        this.payload.description = data.site.description;
        this.payload.socialMedias = data.social;
        if (data?.tags?.length > 0) {
          const dataTags = [];
          data.tags.forEach((value) => {
            dataTags.push(value.name);
          });
          this.payload.tags = dataTags;
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const { name, description, tags, socialMedias } = this.payload;
          const payload = {
            description,
            tags,
            name,
            socialMedias,
          };
          await GeneralService.save(payload);
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/settings"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
