import Vue from "vue";

export default class GeneralService {
  static baseUrl = "general";

  static save(payload = {}) {
    return Vue.axios.post(this.baseUrl, payload);
  }

  static show() {
    return Vue.axios.get(`${this.baseUrl}`);
  }
}
